@import 'assets/scss/abstracts/variables';

.top-box {
  width: 100%;
  display: flex;
}

.top-box-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100px;
  margin-inline: 15px;
  padding: 15px !important;
  box-shadow: $shadow-sm;
  border-radius: 5px;
}

.stats-item {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  border-bottom: 1px solid #919eab3d;
  align-items: center;
  height: 42px;

  & span {
    font-size: 14px;
  }
}

.message-description {
  margin-top: 2px;
}

.custom_box {
  box-shadow: $shadow-light;
}
