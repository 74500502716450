.custom_box {
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    border-radius: 12px;
    flex-direction: column;
    margin-left: 25px;
    box-shadow: $shadow-light;
    &:first-child {
        margin-left: 0px;
    }

    &__title {
        border-bottom: 1px solid #919eab3d;
        padding: 15px;
        font-size: 12px;
        // background-image: linear-gradient(rgba(255,255,255,1) 0%, rgba(251,252,254,1) 100%);
        // border-top-left-radius: 15px;
        // border-top-right-radius: 15px;
    }
}
