
button {
    margin: 5px 0px;
    text-transform: none !important;
    &.disable {
        background-color: #e4b7f3;
    }
    
    &.enable {
        color: #ffff;
        background-color: #8F2AB0;
        &:hover {
            color: #ffff;
            background-color: #8F2AB0;
        }
    }
}