.left-box {
    width: 65%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    &__top {
        display: flex;
        justify-content: space-between;
        height: 120px;

        &__stat {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 26px;
            height: 100%;
        }
    }

    &__activity {
        margin-top: 30px;
    }

    &__logs {
        margin-top: 30px;
    }
}

.right-box {
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    &__details-box {

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            padding: 0 20px;
            border-bottom: 1px solid #D5DDED;
        }

        &__item:last-child {
            border-bottom: none;
        }
    }

    &__audience {
        margin-top: 30px;

        &__item {
            height: 60px;
            padding: 20px;
            border-bottom: 1px solid #D5DDED;
        }

        &__item:last-child {
            border-bottom: none;
        }

    }
}
.disable_edit {
    z-index: -1;
}