.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


.loader_wrapper {
  display: flex;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  background: rgba(0,0,0, .1);
  margin: auto;
  justify-self: center;
  align-self: center;
  position: absolute;
  border-radius: inherit;
  justify-content: center;
  align-items: center;
}
