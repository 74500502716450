@import 'assets/scss/abstracts/variables';

.modal_dialog {
    z-index: 1500 !important;
    .divider_action {
        color: $gray;
    }

    .create_audience,
    .experiments {
        .popup {
            padding: 10px;
            .audience--type {
                padding: 10px 25px;
                box-sizing: border-box;
                border-radius: 5px;
                margin-bottom: 22px;
                padding: 10px;
                background-color: #F9FAFD;
                box-shadow: $shadow-light;


                &.experiments {
                    background-color: #FFF;
                    width: 30%;
                    padding: 0;
                    margin: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                }

                &:not(.experiments) {
                    &:last-child {margin-bottom: 0px}
                }

                label {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    margin: 0px;
                    color: #7d8697;
                    .Mui-checked {
                        color: #8f2ab0;
                    }
                    .MuiTypography-root {
                        font-weight: 500;
                        color: #061942;
                        font-size: 14px;
                    }
                }
                .description {
                    font-weight: 400;
                    color: #7d8697;
                    font-size: 11px;
                    margin: 0px;
                }
                &.checked {
                    border-color: #8f2ab0;
                    background-image: linear-gradient(to right, #fafafa, #faf5ff);
                }
            }
            .audience--title {
                font-size: 12px;
                padding-top: 25px;
                padding-bottom: 10px;
                color: #8291AE;
                &.Mui-focused {
                    color: #8291AE;
                }
            }
        }
        .MuiInputBase-input {
            font-size: 14px;
        }
    }
}
