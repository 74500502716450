#spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0; 
  background-color: rgba(255,255,255,0.7);
  z-index: 9999;
}

@-webkit-keyframes spin {
from {-webkit-transform:rotate(0deg);}
to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
from {transform:rotate(0deg);}
to {transform:rotate(360deg);}
}

#spinner::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 25px;
  height: 25px;
  border-style: solid;
  border-color: #91b6ff;
  border-top-color: transparent;
  border-width: 3px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}