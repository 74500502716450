.csv_selection {
    .csv_selection--create {
        input {
            font-size: 14px;
            width: 400px;
        }
        button {
            color: #061942;
            font-weight: 400;
            margin-left: 10px;
        }
    }
}