@import 'assets/scss/abstracts/variables';

.settings_box {
    box-shadow: $shadow-light;
    box-sizing: border-box;
    // border: 1px solid #EDF1F9;
    border-radius: 12px;
    margin-bottom: 25px;

    label {
        color: #061942;
        margin-bottom: 5px;
    }

    .settings_input {
        .MuiFormControl-root {
            margin-right: 18px;
        }
        > .MuiTypography-root {
            color: #586276;
        }
    }
    .settings_button {
        width: 110px;
        color: #061942;
        font-weight: 400;
        background-color: #e7ebf3;
        padding: 7px 25px;
        margin-left: auto;
    }
}

.multiline_box {
    .MuiTextField-root {
        margin-bottom: 21px;
    }
}
.isLoading {
    background-color: rebeccapurple;
}
