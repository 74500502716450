
.trigger_exit_filters {
    .MuiInputBase-root {
        font-size: 14px;
    }
    .filters_nav {
        display: flex;
        position: sticky;
        top: 0;
        background-color: #FFFFFF;
        z-index: 3;
        button {
            text-transform: none;
            border-radius: 0px;
            font-weight: 400;
            height: 45px;
            width: 150px;
            flex-grow: 1;
            &.active {
                color: #A93CCD;
                border-bottom: 0.5px solid;
                background-color: #F9F6FC;
            }
        }
        margin-bottom: 25px;
        box-sizing: border-box;
        border-bottom: 1px solid #D5DDED;
    }
    
    .trigger_filter {
        padding-left: 25px;
        .MuiTypography-root {
            font-size: 14px !important;
            color: #061942;
        }
        .MuiToggleButtonGroup-root {
            display: flex;
            justify-content: space-around;
            margin-bottom: 60px;
            button {
                text-transform: none;
                color: #4C6BAF;
                font-weight: 400;
                border: none;
                border-radius: 5%;
                &.active {
                    box-shadow: 1px 1px #4C6BAF;
                }
            }
        }
        .filter--metric {
            padding: 0px 25px;
            display: flex;
            flex-wrap: wrap;
            p {
                font-size: 14px;
                align-self: center;
                margin-left: 5px;
            }
            .select_trigger {
                width: 70%;
                margin-right: 10px;
            }
            .MuiFormControl-root {
                width: 60%;
                label {
                    color: #c2c3c7;
                    margin: auto;
                    font-weight: 400;
                }
            }
            .event--values {
                display: flex;
                padding-top: 21px;
                align-items: center;

                :nth-child(2) {
                    margin-left: 5px;
                    margin-right: 5px;
                }

                :nth-child(4) {
                    margin-left: 5px;
                }
                .MuiOutlinedInput-root {
                    input::placeholder {
                        color: #061942;
                        opacity: 1;
                    }
                }
            }

            .subfilter_block {
                display: 'flex';
                flex-wrap: 'wrap';
                .MuiFormControl-root {
                    width: fit-content !important;
                    margin: 18px 5px 10px 5px;
                }
                & :first-child {
                    margin-left: 0px;
                }
            }
            .subfilter_value {
                .MuiInputBase-root {
                    width: 100px;
                }
            }
        }
        .not_active {
            pointer-events: none;
            color: rgb(170, 170, 170);
        }   
        .not_active--radio {
            span {
                color: rgb(170, 170, 170);
            }
        }
    }
}