@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

.container {
  max-width: 100%;
  padding-right: 5rem;
  padding-left: 1rem;
  color: #333;
}

h3,
.h3 {
  margin-bottom: 1rem;
}

label.MuiInputLabel-root,
label.MuiFormLabel-root {
  margin-top: -1px;

  &.MuiFormLabel-filled {
    padding: 0px 10px !important;
    margin-top: -4px !important;
    background-color: transparent !important;
  }
}

.MuiAppBar-root {
  box-shadow: $shadow-sm;

  & .container-fluid {
    padding: 0;

    & .navbar-brand {
      filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .15));
      max-width: 5rem;
      margin-left: 1rem;
    }

    & .search-form {
      margin-left: 0rem;

      & .MuiInputBase-root,
      & button {
        outline: none;
        border: 0;
        font-size: 14px;
      }

      & .MuiInputBase-root {
        transition: all .4s cubic-bezier(.4, 0, .2, 1) 100ms;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        background: rgba(0, 0, 0, .25);
        margin-right: 10px;
        color: rgba(255, 255, 255, .75);
        text-shadow: 1px 1px 2px rgba(0, 0, 0, .15);

        & .MuiInputBase-input {
          padding: 4px 14px 8px 14px !important;
        }

        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, .35);
        }

        &:focus,
        &:active {
          color: #333;
          background: rgba(255, 255, 255, 1);
          outline: none;
          border: 0;
        }
      }

      & .btn {
        background: rgba(0, 0, 0, .25);
        color: rgba(255, 255, 255, .4);
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        text-shadow: 1px 1px 2px rgba(0, 0, 0, .25);

        &:hover {
          background: rgba(0, 0, 0, 0.325);
          color: #FFF;
        }

        &:focus,
        &:active {
          color: #FFF;
          background: #23292E;
        }
      }

      & form {
        display: inline-flex;
        width: 100%;
      }
    }

    & .menu {
      filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .15));

      & a.nav-link {
        font-size: 14px;
        color: #FFF;
        font-weight: 300;
      }

      & .userDropdown {
        padding: 0;
        color: #FFF;
        height: 36px;
        margin-top: auto;
        margin-bottom: auto;

        & img {
          margin-right: 10px;
          margin-left: 15px;
          margin-top: auto;
          margin-bottom: auto;
        }

        & .dropdown {
          margin-top: auto;
          margin-bottom: auto;
          max-height: 34px;
          position: relative;

          & .dropdown-menu {
            position: absolute;
            box-shadow: 0 6px 18px rgba(27, 33, 117, 0.08), 0 3px 6px rgba(0, 0, 0, .06);
            border: 0;
            margin-top: .5rem;
            margin-left: -2.5rem;
          }

          & .btn {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.card,
.MuiTableContainer-root,
.segments_table .MuiCard-root,
.segments_table .MuiTableContainer-root,
.single_segment_table.MuiCard-root,
.segments_table_search.MuiCard-root {
  border-radius: $border-radius;
  box-shadow: 0 5px 18px rgba(27, 33, 117, 0.08), 0 3px 6px rgba(0, 0, 0, .06) !important;
}

.bottom-nav {
  position: fixed;
  display: flex;
  box-shadow: $shadow-op;
  background-color: #FFF;
  z-index: 100;
  flex: 1 1 auto !important;
  justify-content: flex-end;
}

.placeholder,
.holder {
  cursor: pointer;
  background: transparent;
  padding: 2rem;
  color: $primary;
  border: 2px dashed $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 7px;
  transition: all .4s cubic-bezier(.4, 0, .2, 1) 100ms;
  filter: grayscale(100);
  text-align: center;

  & img {
    margin-bottom: 1rem;
  }

  &:hover {
    filter: grayscale(0);
  }
}

.inline {
  background: #eff0f3;
  padding: 1.5rem 2rem;
  color: $primary;
  width: 100%;
  transition: all .4s cubic-bezier(.4, 0, .2, 1) 100ms;
  text-align: center;
  border-radius: 8px;
}

.variants {
  background: inherit !important;
  max-height: 140px;
  height: 140px;
  padding-bottom: 10px;
  min-width: 1000px;
  width: 100% !important;
  display: inline-block !important;
  margin: 0px !important;
  box-shadow: none !important;
  overflow: hidden !important;

  & .MuiCardContent-root {
    background: inherit !important;
    height: 120px !important;
    display: flex !important;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px !important;
    padding-bottom: 24px !important;
    padding-top: 10px !important;
    margin-left: 10px;
  }

  & .variantList {
    margin-right: 1rem;
    position: relative;
    display: block;
    text-align: center;

    & .delete {
      position: absolute !important;
      margin: 0 !important;
      padding: 0 !important;
      display: none;
      transition: all .4s cubic-bezier(.4, 0, .2, 1) 100ms;
    }

    &:hover .delete {
      transition: all .4s cubic-bezier(.4, 0, .2, 1) 100ms;
      position: absolute;
      display: block;
    }
  }
}

.MuiButton-root {
  &.inverted-label-button {
    border: 1px solid #D5DDED;
    background: #F9FAFD;
    color:#061942;
    padding: .7rem 1.7rem;
    text-transform: capitalize;
    width: 100%;
    margin-bottom: 24px;

    &:hover {
        background: $primary;
        color: #FFF;
        cursor: pointer;
        border-color: $primary !important;
    }
  }

  &.primary-button {
    border: 1px solid $primary;
    background: $primary;
    color: #FFF;
    padding: .7rem 1.5rem .7rem 1rem;
    text-transform: capitalize;
    font-weight: 400;
    width: max-content;

    &:hover {
      background: $primary;
      color: #FFF;
      cursor: pointer;
    }

    &[disabled] {
      color: $gray;
      border-color: $gray;
      background: $lighter;
    }
  }

  &.primary-button-light {
    border: 1px solid $primary-light;
    background: $primary-light;
    padding: .7rem 1.5rem .7rem 1rem;
    color: $primary;
    text-transform: capitalize;
    width: max-content;
    font-weight: 400;

    &:hover {
      background: $primary-light;
      color: $primary;
      cursor: pointer;
    }
  }

  &.secondary-button {
    border: 1px solid $secondary;
    background: $secondary;
    color: #FFF;
    text-transform: capitalize;
    width: 100%;
    font-weight: 400;

    &:hover {
      background: $secondary;
      color: #FFF;
      cursor: pointer;
    }
  }

  &.secondary-button-light {
    border: 1px solid $secondary-light;
    background: $secondary-light;
    color: $secondary;
    text-transform: capitalize;
    font-weight: 400;
    width: 100%;

    &:hover {
      background: $secondary-light;
      color: $warning;
      cursor: pointer;
    }
  }

  &.warning-button {
    border: 1px solid $warning;
    background: $warning;
    color: #FFF;
    text-transform: capitalize;
    font-weight: 400;
    width: 100%;

    &:hover {
      background: $warning;
      color: #FFF;
      cursor: pointer;
    }
  }


  &.warning-button-light {
    padding: 10px;
    border: 1px solid $warning-light;
    background: $warning-light;
    color: $warning;
    text-transform: capitalize;
    font-weight: 400;
    width: 100%;

    &:hover {
      background: $warning-light;
      color: $warning;
      cursor: pointer;
    }
  }

  &.success-button {
    border: 1px solid $success;
    background: $success;
    color: #FFF;
    text-transform: capitalize;
    font-weight: 400;

    &:hover {
      background: $success;
      color: #FFF;
      cursor: pointer;
    }
  }

  &.success-button-light {
    padding: 10px;
    border: 1px solid $success-light;
    background: $success-light;
    color: $success;
    text-transform: capitalize;

    &:hover {
      background: $success-light;
      color: $success;
      cursor: pointer;
    }
  }
}

.MuiOutlinedInput-root {
  border: 0;

  & fieldset {
      border-color: $light;
  }

  &:hover fieldset {
      border-color: $light !important;
  }

  &.Mui-focused fieldset {
      border-color: $light !important;
  }
}


.shadow-op {
  box-shadow: $shadow-op;
}
