$primary:#A93CCD;
$primary-light:#FAECFF;
$secondary:#5694EA;
$secondary-light:#E6F5FD;

$warning: #F78D52;
$warning-light: #FDF0EA;
$purple: #8F2AB0;
$purple-light: #FDF6FF;
$info-light: #EEF4FD;
$info: #5694EA;

$error: #EA6157;
$error-light: #f6dad8;

$success: #408B85;
$success-light: #ecfffd;

$inactive: #6E6E89;
$default-dark: #061942;
$default: #2B437A;
$default-light: #4C6BAF;
$darkgray: #373f50;
$lightgray: #F9FAFD;
$gray: #8291AE;
$light: #D5DDED;
$lighter: #F6F8FC;

$body-color: #F9FAFD;

$shadow-op: 0 5px 18px rgba(27, 33, 117, 0.08), 0 3px 6px rgba(0, 0, 0, .06);
$shadow-hv: 0 8px 16px rgba(27, 33, 117, 0.07),  0 7px 12px rgba(0, 0, 0, .05);
$shadow-sm: 0 3px 9px -1px rgba(27, 33, 117, 0.08), 0 3px 6px 0px rgba(0, 0, 0, .04);
$shadow-light:rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;;

$shadow-default: 0 5px 18px rgba(110, 110, 137, 0.1), 0 3px 6px rgba(110, 110, 137, .08);
$shadow-warning: 0 5px 18px rgba(232, 106, 46, 0.1), 0 3px 6px rgba(232, 106, 46, .08);
$shadow-success: 0 5px 18px rgba(74, 165, 156, 0.1), 0 3px 6px rgba(74, 165, 156  , .08);
$shadow-purple: 0 5px 18px rgba(169, 60, 205, 0.1), 0 3px 6px rgba(169, 60, 205, .08);

$text-shadow: drop-shadow(0px 1px 2px rgba($gray, .5));

$transition: all .2s cubic-bezier(.4, 0, .2, 1) 100ms;

$border-radius: 16px;

.shadow-light {
  box-shadow: $shadow-light !important;
}
