@import 'assets/scss/abstracts/variables';
@import 'assets/scss/abstracts/mixins';

.flow-node,
.react-flow__node {
  font-size: 12px;
  color: #333333;
  min-width: 250px;
  max-width: 250px;
  margin: auto;
  cursor: default;
  position: absolute;
  margin-top: 0px;


  &.drop-target-available {
    border: 3px dotted #666;
  }

  &.drop-target-over {
    border: 3px dashed #FFF;
  }

  &.react-flow__node-email,
  &.react-flow__node-wait,
  &.react-flow__node-sms,
  &.react-flow__node-branch_filter {
    background: #FFF !important;
    border: 1px solid $light;
    //border: 1px dashed #9FB1D5;
    border-radius: calc($border-radius/3);
    //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%239FB1D5FF' stroke-width='3' stroke-dasharray='10%2c 15' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  &.selected,
  &.selected:hover {
    &.react-flow__node-email,
    &.react-flow__node-wait,
    &.react-flow__node-sms,
    &.react-flow__node-branch_filter {
      background-color: #FFF;
      //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23A93CCDFF' stroke-width='3' stroke-dasharray='10%2c 15' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e");
    }

    &.react-flow__node-email {
      border-color: $primary;
      box-shadow: $shadow-purple;
    }

    &.react-flow__node-sms {
      border-color: $primary;
      box-shadow: $shadow-purple;
    }

    &.react-flow__node-wait {
      border-color: $warning;
      box-shadow: $shadow-warning;
    }

    &.react-flow__node-branch_filter {
      border-color: $success;
      box-shadow: $shadow-success;
    }

    &.react-flow__node-default {
      box-shadow: $inactive;
      box-shadow: $shadow-success;
    }
  }

  & .flow-node__header {
    max-width: 250px;
    max-height: 40px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 8px;
    // m argin: auto;
    border-radius: calc($border-radius/3);

    & .node-icon {
      display: flex;
      justify-content: center;
      align-self: center;
      align-items: center;
      border-radius: 50%;
      padding: 3px;
      color: $inactive;
      border: 1px solid $inactive;

      & svg {
        font-size: 16px;
      }
    }
  }

  & .flow-message-node {
    color: $inactive;
    min-height: 38px;
    border-radius: calc($border-radius/3);
    position: relative !important;
    transition: $transition;

    &.disabled {
      opacity: .7;
      background: $lighter;
    }

    & .flow-node__header {
      padding: 6px;
    }

    &:hover {
      box-shadow: $shadow-light;
    }

    & .node-icon {
      color: $primary;
      border: 1px solid $primary;
      background: $primary-light;
    }
  }

  & .flow-branch-node {
    border-radius: calc($border-radius/3);
    align-items: center;
    color: $inactive;
    position: relative !important;
    transition: $transition;

    &.disabled {
      opacity: .7;
      background: $lighter;
    }

    &:hover {
      box-shadow: $shadow-sm;
    }

    & .node-icon {
      color: $success;
      border: 1px solid $success;
      background: $success-light;
    }
  }

  & .flow-wait-node {
    align-items: center;
    color: $inactive;
    border-radius: calc($border-radius/3);
    position: relative !important;
    transition: $transition;
    background: #FFF;

    height: 40px;

    &.disabled {
      opacity: .7;
      background: $lighter;
    }

    &:hover {
      box-shadow: $shadow-sm;
    }

    & .node-icon {
      color: $warning;
      border: 1px solid $warning;
      background: $warning-light;
    }
  }

  & .message-attributes {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    justify-content: center;
    color: $gray !important;
    margin-inline: auto;

    & .attribute-container {
      display: inline-flex;

      &:first-of-type {
        top: -30px;
        position: absolute;
      }

      &:nth-of-type(2) {
        position: absolute;
        top: -60px;
      }
    }

    & .attribute {
      font-size: 12px;
      color: $gray !important;
    }
  }

  & .flow-node__body {
    background: inherit;
    width: 100%;
    padding: 0px 4px 2px;
    display: block;
    border-radius: calc($border-radius/3);
    max-width: 275px;
    word-wrap: break-word;
  }

  & .bubble-wrapper {
    position: absolute;
    right: -35px;
    top: 0;
    margin-left: 0px;

    &.disabled {
      & .bubble {
        opacity: .5;
      }
    }

    & .bubble {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 50%;
      margin-bottom: 4px;
      transition: $transition;

      &:hover {
        box-shadow: $shadow-sm;
      }

      &.bubble-delay {
        background: $warning-light;
        border: 1px solid $warning;
        color: $warning;

        & .bubble-delay__desc {
          margin-inline: 4px;
          font-size: 10px;
          font-weight: 400;
          transition: $transition !important;
        }

        &.active,
        &:hover {
          background: $warning;
          color: #FFF;
        }

        // &.active {
        //   overflow: hidden;
        //   width: 30px;
        //   border-radius: 25px;
        //   transition: $transition !important;

        //   &:hover {
        //     width: 150px;
        //     transition: $transition !important;

        //     & .bubble-delay__desc {
        //       transition: $transition !important;
        //       display: inline-flex;
        //     }
        //   }
        //}
      }

      &.bubble-exit {
        background: $secondary-light;
        border: 1px solid $secondary;
        color: $secondary;

        &.solid,
        &:hover {
          background: $secondary;
          color: #FFF;
        }
      }

      & .MuiSvgIcon-root {
        font-size: 18px;
      }

      &.bubble-issues {
        background: $error-light;
        border: 1px solid $error;
        color: $error;

        &.solid,
        &:hover {
          background: $error;
          color: #FFF;
        }
      }

      & .MuiSvgIcon-root {
        font-size: 18px;
      }
    }
  }

  & .flow-enter-node {
    border: 1px solid #9FB1D5;
    color: $inactive;
    border-radius: calc($border-radius*2);
    background: #FFF;
    padding: 6px;
    margin: auto;
    display: flex;
    position: relative !important;
    justify-content: space-between;
    width: max-content;
    pointer-events: all;

    &.highlight {
      border-color:$warning;
      color: $warning !important;
      background: $warning-light;

      & svg {
        color: $warning;
      }
    }

    &.disabled {
      opacity: .7;
      background: $lighter;
    }
  }

  & .flow-merged-node {

    &.drop-target-available {
      border: 3px dotted #666;
    }

    color: $inactive;
    background: transparent;
    position: relative !important;
    display: flex;
    align-items: center;
    justify-self: center;
    width: max-content;
    opacity: 1;
    margin: auto !important;
    height: 1px;
  }

  &.react-flow__node-exit {
    color: $inactive;
    background: transparent;
    padding: 6px;
    margin: auto;
    display: flex;
    position: absolute !important;
    justify-content: center;
    text-align: center;
    margin-top: -10px;
  }

  & .flow-merge-node,
  & .flow-exit-node {
    color: $inactive;
    border: 1px solid rgb(214, 214, 214);
    margin-bottom: 5px !important;
    border-radius: calc($border-radius*2);
    padding: 6px;
    background: #FFF;
    position: relative !important;
    display: flex;
    align-items: center;
    justify-self: center;
    width: max-content;
    opacity: 1;
    margin: auto !important;
    pointer-events: all;

    &.highlight {
      border-color:$warning;
      color: $warning important !important;
      background: $warning-light;

      & svg {
        color: $warning;
      }
    }

    &.disabled {
      opacity: .7;
      background: $lighter;
    }
  }

  & .flow-merge-node {
    cursor: move;
    background: transparent;
    border: 0;


    &:active,
    &.active {
      pointer-events: all;
      border: 1px dashed rgb(214, 214, 214);
      opacity: .65;

      & .MuiTypography-root,
      & .MuiSvgIcon-root {
        pointer-events: none;
      }

      & svg {
        font-size: 20px;
      }
    }

    &:hover {
      filter: $text-shadow;
    }
  }

}

.issue-notice {
  background: inherit;
  width: 100%;
  padding: 4px 10px;
  display: block;
  border-radius: $border-radius;
  color: $inactive;
}

.react-flow__edgeupdater {
  cursor: default;
  pointer-events: all;
}
.react-flow__handle.connectable {
  cursor: default;
}
