.subtitle {
    font-size: 12px;
    color: #8291AE;
    font-weight: 400;
    margin: 0px;
}

.btns {
    .primary-button-light {
        text-transform: none !important;
        margin-right: 10px;
    }
    .csv_btn {
        background-color: #faecff;
        color: #8F2AB0;
        font-weight: 400;
        border: none;
        &:hover {
            background-color: #faecff;
        }
        svg {
            padding-right: 7px;
        }
    }

    .new_btn {
        text-transform: none;
        background-color: #8F2AB0;
        color: #ffff;
        font-weight: 400;
        border: none;

        &:hover {
            background-color: #8F2AB0;

        }
    }
}

.audience_name {
    img {
        vertical-align: bottom;
        padding-right: 5px;
    }

    a {
        text-decoration: none;
        color: #061942;
        &:visited {
            color: #061942;

        }
    }
}
