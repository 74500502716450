.data_types {
    width: 100px;
    > div {
        font-size: 14px;
    }
}
.csv_mapping {
    td {
        &:last-child {
            width: 400px
        }
        .property {
            div {
                font-size: 14px;
            }
        }
    }
}
