@import 'reactflow/dist/style.css';
// @import 'react-flow-renderer/dist/theme-default.css';
@import 'FlowBuilder';
@import 'FlowNodes';
@import 'FlowHeader';
@import 'FlowSidebar';


.flow-header__status {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex-shrink: 0;
  padding: 2px 0px;

  &.secondary {
    background: $secondary-light !important;
    color: $secondary !important;
  }

  &.primary {
    background: $primary-light !important;
    color: $primary !important;
  }

  &.default {
    background: $lightgray !important;
    color: $gray !important ;
  }
}
