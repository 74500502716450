@import 'assets/scss/abstracts/variables';

.card {
    color: var(--bs-default);
    width: 100%;
    margin: 10px;
    border: 0;
    box-shadow: 0 5px 18px rgba(27, 33, 117, 0.08), 0 3px 6px rgba(0, 0, 0, .06);

    & .card-header {
        color: var(--bs-default);
        border-bottom: 0;
    }

    & label,
    & .card-title,
    & .card-subtitle {
        color: var(--bs-default);
    }

    & label.MuiFormLabel-filled,
    & label.MuiFocused {
        padding: 0px 10px !important;
        margin-top: -3px !important;
        background-color: #FFF !important;
    }

    & .form-control {
        color: var(--bs-default);
    }

    & .MuiSelect-select:focus {
        background-color: transparent;
    }
}

.review {
    width: 90%;
    margin: auto;

    & .accordion-button.collapsed {
        background: #fff !important;
    }

    & .accordion-button:not(.collapsed) {
        border: 0;
        box-shadow: none;
        background: #fff !important;
    }
}

.campaign-name {
    color: $darkgray !important;
    margin-left: 1rem !important;
    flex-grow: 0;
    margin-left: 15px;
    margin-right: 15px;
    color: $default;
    flex-shrink: 0;
    padding: 0px;
    width: max-content;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
}

.campaign-box {
    max-width: 1200px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    border: 1px solid #EDF1F9;
    box-sizing: border-box;
    box-shadow: $shadow-op;
    border-radius: 8px;
    padding: 1.75rem 1rem 0rem 1rem;
    text-align: left;

    & .MuiOutlinedInput-root {
        margin-top: 10px;
        margin-bottom: 24px;
        color: black;
    }

    & .Mui-focoused {
        border-color: #D5DDED !important;

        & .MuiOutlinedInput-notchedOutline {
            background: #FFFFFF;
            color: $gray;
            border: 1px solid #D5DDED;
            border-color: #D5DDED !important;
        }
    }
}

.audiences-box {
    margin-left: auto;
    margin-right: auto;
    width: 1000px;

    & .inverted-label-button {
        margin-top: 25px;
        color: $gray;
    }
}

.sms-box{
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    border: 1px solid #EDF1F9;
    box-sizing: border-box;
    box-shadow: $shadow-op;
    border-radius: 8px;
    padding: 1.75rem 1rem 0rem 1rem;
    text-align: left;

}

.box {
    margin: 20px;
    padding: 25px 15px;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    & .description {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
        width: 100%;
        color: $gray;
        padding: 5px;
        font-size: 14px;
    }

    & .inverted-label-button {
        align-self: flex-end;
        border-radius: 4px;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 0px;
        color: $gray;

        &.warning-label-button {
            text-transform: capitalize;
            background: $warning;
            border-color: $warning;
            color: #FFF;

            &:hover {
                border-color: $warning;
                background: $warning;
            }
        }

        &.purple-label-button {
            text-transform: capitalize;
            background: $purple;
            border-color: $purple;
            color: #FFF;

            &:hover {
                border-color: $purple;
                background: $purple;
            }
        }

        &.info-label-button {
            background: $info;
            border-color: $info;
            color: #FFF;
            text-transform: capitalize;

            &:hover {
                border-color: $info;
                background: $info;
            }
        }
    }

    & .box-one {
        margin-left: auto;
        background: $warning-light;
        color: $warning;
        padding: 15px;
        border-radius: 50%;
        display: inline-flex;
        justify-self: flex-start;

        & .MuiSvgIcon-root {
            display: flex;
            justify-self: center;
            align-items: center;
        }
    }

    & .box-two {
        margin-left: auto;
        background: $purple-light;
        color: $purple;
        padding: 15px;
        border-radius: 50%;
        display: inline-flex;
        justify-self: flex-start;

        & .MuiSvgIcon-root {
            display: flex;
            justify-self: center;
            align-items: center;
        }
    }

    & .box-three {
        margin-left: auto;
        background: $info-light;
        color: $info;
        padding: 15px;
        border-radius: 50%;
        display: inline-flex;
        justify-self: flex-start;

        & .MuiSvgIcon-root {
            display: flex;
            justify-self: center;
            align-items: center;
        }
    }
}

.email-content {
    width: auto;
    max-width: 1200px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.75rem 1rem 0rem 1rem;
    text-align: left;
    flex-wrap: wrap;

    & .box {
        min-width: 300px;
    }
}


.send-preview {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 1000px;
    justify-self: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}

.full-preview {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    width: 550px;
    justify-self: center;
    justify-content: center;

    & .preview-media {
        max-width: 550px;
        min-height: 1000px;
        background-clip: inherit;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.sms-preview{
    display: block;
    float: left;
    border: solid 10px #000;
    width: 336px;
    height: 300px;
    padding: 0px;
    border-radius: 50px;
    -webkit-mask-image: linear-gradient(180deg, rgb(0, 0, 0) 44%, transparent 87%)
}

.top-section {
    background: #f6f6f7;
    height: 93px;
    margin: 0px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom: #e6e6e6 1px solid;

}

.top-section-time {
    position: absolute;
    margin-left: 30px;
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
}

.top-section-symbols {
    position: absolute;
    margin-left: 250px;
    margin-top: 10px;
    font-size: 12px;
    i{
        margin-right: 4px
    }
}

.arrow {
    position: absolute;
    border: solid #3478f6;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 45px;
    margin-left: 13px;
    border-radius: 2px;
    opacity: 0.9;
    transform: rotate(135deg);
}
.top-section-middle {
    margin: auto;
    width: 180px;
    background: black;
    height: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top: 5px;
}

.top-section-user-pic {
    margin: auto;
    margin-top: 12px;
    width: 30px;
    height: 40px;
    border-radius: 100%;
    background-color: none;
    font-size: 40px;
    color: #999999;
}

.top-section-user-name {
    margin: auto;
    margin-top: 4px;
    background: none;
    height: 10px;
    width: 100px;
    font-size: 12px;
    text-align: center;
}
.speaker {
    width: 60px;
    height: 8px;
    background: #2e2e2e;
    margin-left: 55px;
    border-radius: 10px;
}
.front-camera {
    height: 8px;
    width: 8px;
    background: #005063;
    border-radius: 100%;
    margin-left: 70px;
}

.messages-section {
    margin-top: 85px;
    height: 100%;
    font-size: 13px;
    font-weight: 600;
}

.message {
    border-radius: 20px 20px 20px 20px;
    margin: 8px 15px 10px;
    padding: 10px 15px;
    position: relative;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.4s;
}

.message.from {
    background-color: #e9e9ea;
    color: #363636;
    margin-right: 80px;
}

.message.from:before {
    content: "";
    width: 0;
    height: 0;
    right: 93%;
    top: 56%;
    position: absolute;
    border-top: 13px solid transparent;
    border-right: 26px solid #e9e9ea;
    border-bottom: 13px solid transparent;
    border-radius: 40px;
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg);
}

.inbox-header-item {
    width: 1; // This makes the box take the full width of its parent
    white-space: 'nowrap'; // Prevents the text from wrapping to the next line
    overflow: 'hidden'; // Keeps the text within the box boundaries
    text-overflow: 'ellipsis'; // Adds "..." at the end if the text is too long
}

.inbox-preview {
    border-radius: 10px;
    width: 100%;
    box-shadow: $shadow-sm;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    margin-bottom: 10px;



    & header {
        display: flex;
        color: $gray;
        justify-content: flex-start;
        width: 100%;
        padding: 15px 20px;
        font-weight: 400;

        & .MuiSvgIcon-root {
            font-size: 24px;
        }

        & .title {
            padding: 0;
            margin-top: 0;
            font-weight: 400;
            margin-bottom: 0;
            margin-left: 10px;
        }
    }

    & .MuiCardContent-root {
        width: 100%;
        padding: 0;

        & .messages {
            padding: 0;

            & .preview-message {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                padding: 10px 25px;
                width: 100%;
                border-top: 1px solid $light;
                background-color: #F9F6FC;
                color: $gray;
                font-weight: 300;

                &.skeleton {
                    background-color: #FFF;
                }

                & .sender-name {
                    color: #061942;
                    font-weight: 400
                }

                & .skeleton {
                    display: flex;
                    border: 0;
                    margin-right: 30%;
                    text-align: left;
                    justify-content: flex-start;
                    width: 70%;
                    border-radius: 5px;
                    height: 10px;
                    background: #F0F3F9;
                }
            }
        }


        & .issues {
            padding: 0;

            & .preview-issues {
                display: flex;
                align-items: center;
                padding: 10px 25px;
                width: 100%;
                border-top: 1px solid $light;
                background-color: #FFF;
                color: $gray;
                font-weight: 300;

                & .issue-label {
                    display: inline-flex;
                    color: #061942;
                }

                & .status {
                    display: flex;
                    justify-content: flex-end;
                    margin-left: auto;

                    &.ok {
                        color: $success;
                    }
                    &.error {
                        color: $warning;
                    }
                }
            }
        }
    }
}


.send-preview-option-card {
    display: inline-flex;
    margin-bottom: 10px;
    box-shadow: $shadow-sm;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0;
    border-radius: 10px;

    & header {
        min-height: 50px;
        display: flex;
        color: $gray;
        justify-content: flex-start;
        width: 100%;
        padding: 15px;
        font-weight: 400;

        & .MuiSvgIcon-root {
            font-size: 24px;
        }

        & .title {
            padding: 0;
            margin-top: 2px;
            font-weight: 400;
            margin-bottom: 0;
            margin-left: 10px;
        }
    }

    & footer {
        width: 100%;
        min-height: 60px;
        padding: 5px 15px;
        display: flex;
        align-items: center;

        & .MuiTypography-root {
            color: $gray;
            font-weight: 400;
        }
    }

    & .MuiCardContent-root {
        height: 100%;
        width: 100%;
        border-top: 1px solid $light;
        border-bottom: 1px solid $light;

        & .send-options-audiences {
            display: flex;
            align-items: center;
        }

        & .schedule-input .MuiInput-underline {
            border: 1px solid $light;
            padding: 10px 15px;
            border-radius: $border-radius;

            &:before,
            &:after {
                display: none;
            }
        }
    }
}


.view-templates {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: auto;
    justify-self: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    & .box {
        box-shadow: $shadow-op;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        height: 100px;
        align-items: center;
        padding: 0 25px;

        & .description {
            padding: 0;
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    & .search-templates {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px;

        & .search-field {
            box-shadow: none;
            color: $gray;
            margin-right: 20px;
            background: #FFF;
            border-color: $light;
        }

        & .search-sort {
            & .MuiButton-outlined,
            & .MuiButton-outlined:not(:last-child) {
                border-color: $light !important;
                color: $gray;
            }

            & .MuiButton-outlined:last-child {
                padding-left: 0px;
                padding-right: 0px;
            }

            & .MuiSvgIcon {
                color: $gray;
            }
        }
    }

    & .search-results {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 25px;

        & .MuiTypography-body2 {
            color: $gray;
        }
    }

    & .results {
        display: flex;
        margin: 15px;

        & .template-preview {
            border: 0;
            padding: 0;
            width: 215px;
            position: relative;
            max-height: 300px;

            & .preview-media {
                box-shadow: $shadow-op;
                width: 215px;
                height: 400px;
                overflow: hidden;
                background-position: center;
                background-size: cover;
                border-radius: 0px;
            }

            &:nth-child(2n+2) {
                margin-left: 10px;
                margin-right: 10px;
            }

            & .MuiCardContent-root {
                position: absolute;
                padding-left: 20px;
                padding-right: 20px;
                text-align: left;
                width: 100%;
                background: #FFF;
                border: 1px solid #EDF1F9;
                margin-bottom: 0;
                height: 0;
                border-radius: 3px 3px 0 0;
                transition: $transition;

                &.active {
                    transition: $transition;
                    height: 100%;
                }
            }
        }
    }
}

.preview-template {
    & .MuiPaper-root {
        padding: 25px;
        overflow: inherit;
    }

    & .preview-media {
        border-radius: 10px;
        width: 450px;
        height: 700px;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 25px;
    }

    & .warning-button {
        max-width: 150px;
        margin-left: auto;
    }
}


.MuiTabs-root {
    border-bottom: 1px solid #D5DDED;
    min-height: 50px !important;

    & .MuiTab-root {
        min-height: max-content;
        height: 50px;
        display: flex !important;
        align-items: center;
        justify-self: center;
        font-size: 14px;
        font-weight: 400;
        color: $gray;
        text-transform: capitalize !important;
        padding: 0px 15px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-self: center;
        align-items: center;
        vertical-align: middle;

        & .MuiSvgIcon-root {
            display: flex;
            margin-bottom: 0px;
            margin-right: 10px;
            font-size: 26px;
        }

        &.Mui-selected {
            color: $primary;
        }

    }

    & .MuiTabs-indicator {
        background-color: $primary !important;
    }
}

.scheduled {
    box-sizing: border-box;
    border: 1px solid #EDF1F9;
    border-radius: 10px;
    margin: 30px;
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    &__info {
        display: flex;
        justify-content: space-around;

        &__col {
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }


    &__preview {
        margin: 30px;
        width: 30rem;
        height: 30rem;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__audience_item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border-bottom: 1px solid #D5DDED;

        a {
            text-decoration: none;
            color: #061942;
            &:visited {
                color: #061942;

            }
        }

        img {
            padding-right: 5px;
        }
    }
}


.messages-tab-nav.active {
    color: $primary !important;
    border-color: $primary !important;
}

.design-from-scratch {
    display: flex;
    border-radius: 10px;
    border: 1px solid $gray;
    width: 225px;
    height: 100%;
    background: #fff;
    margin-left: 25px;
    box-shadow: $shadow-sm;
    align-items: center;
    justify-content: center;
    flex-direction:column;
}


.edit-email-content::after:hover {
        width: 100px;
        height: 100px;
        background: black;
        z-index: 1000;
        overflow: absolute;
}
