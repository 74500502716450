.side_menu {
    min-width: 300px !important;
    width: auto;
    overflow: hidden;
    flex-shrink: 1;
    padding-bottom: 50px;
    &.logo {
        padding-left: 10px;
        margin-top: -5px;
        padding-bottom: 30px;
    }

    & .side_menu--item {
        width: 90%;
        margin: auto;
        margin-left: 7px;
        margin-bottom: 10px;
        padding: 12px 8px;
        border-radius: 4px;

        &.primary {
            background: $primary;
            color: #FFF;

            & svg {
                color: #FFF;
            }
        }


        & .side_menu--icons {
            & .MuiSvgIcon-root {
                font-size: 24px;
            }
        }

        & .MuiListItemIcon-root,
        & .MuiListItemText-root {
            font-size: 14px;
            margin: 0px 10px;
            min-width: max-content;
        }
    }
}

.makeStyles-drawerClose-5 {
    width: 85px !important;

    & .side_menu {
        & .MuiListItem-root {
            &:hover,
            &.Mui-selected {
                width: 61px;
                margin-left: 7px;
            }
        }

        & .MuiListItemText-root {
            display: none !important;
        }
    }
}
