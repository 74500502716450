
.csv_dropzone {
    padding-top: 50px;
    .dropzone {
        height: 148px;
        width: 700px;
        border: dashed 2px #8F2AB0;
        padding: 8px 16px 8px 8px;
        border-radius: 5px;
        cursor: pointer;
    }
    .dropzone--content {
        display: flex;
        justify-content: center;
        margin: auto;
        padding: 50px;
        img {
            padding-right: 7px;
        }
        p {
            margin: auto;
            font-size: 14px;
        }
    }
}

.csv_title {
    h6 {
        font-size: 17px;
    }
}