.selected_navigation_list-container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;

    &.inverted {
        border: 0;
    }
}

.selected_navigation_list {
    width: 270px;
    color: #061942;
    box-sizing: border-box;
    border: 1px solid #EDF1F9;
    padding: 10px !important;
    border-radius: 7px;
    .Mui-selected  {
        color: #D99E26;
        background-color: #fff3dc !important;
        box-sizing: border-box;
        border-radius: 7px;
    }
    .MuiListItemButton-root:hover {
        background-color: #FEFCF9 !important;
        border-radius: 7px;
    }
}
