.query-builder-container {
    .query-builder {
        font-size: 16px;
        margin: 0px !important;
        .group-or-rule-container {
            .group-or-rule {
                 & ::before {
                    border-color: #D5DDED;
                  }
                  & ::after {
                    border-color: #D5DDED;
                }
            }
            .group {
                background-color: #F7FAFE;
                border-color: #E1ECFB;
                .rule_group--children {
                    .rule {
                        border: none;
                        padding: 0px;
                        margin-left: -14px;
                    }
                    }
                .group--header {
                    position: relative;
                    .group--conjunctions {
                        > span {
                            position: absolute;
                            right: 60px;
                        }
                        .MuiButton-containedPrimary {
                            background-color: #5694EA;
                        }
                        button {
                            text-transform: none;
                            font-weight: 400;
                            &.MuiButton-contained{
                                background-color: #EFF5FD;
                                &.MuiButton-containedPrimary {
                                    background-color: #5694EA;
                                }
                            }
                            .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
                                border-right: 1px solid #5694EA;
                                opacity: 20%;
                            }
                        }
                    }
                    .group--actions {
                        justify-content: flex-start;
                        :nth-child(3) {
                            position: absolute;
                            right: 19px;
                        }
                        button {
                            color: #5694EA;
                            text-transform: none;
                            font-weight: 400;
                            svg {
                                width: 17px;
                                height: 17px;
                            }
                        }

                    }
                }
                .group--field {
                    align-self: left;
                    .MuiInputBase-root {
                    font-size: 14px;
                    padding-left: 5px;
                    width: 300px;
                    height: 30px;
                    border: 1px solid #D5DDED;
                    border-radius: 3px;
                    &::before {
                        border-bottom: none;
                        }
                    }
                }
            }

            .rule {
                border: 1px solid #EDF1F9;
                display: flex;

            .rule--body {
                display: flex;
                flex-wrap: wrap;
                .MuiInputBase-root {
                    margin: 5px;
                    font-size: 14px;
                    padding-left: 5px;
                    height: 30px;
                    border: 1px solid #D5DDED;
                    border-radius: 3px;
                    &::before {
                        border-bottom: none;
                    }
                }

                .rule--field {
                    vertical-align: top;
                    .MuiInputBase-root {
                        margin: 5px;
                        .MuiInput-underline
                        &::before {
                            border-bottom: none;
                        }
                        &::after {
                            border-bottom: 1px solid #8d91a5;
                        }
                    }
                }

                .rule--operator { margin-right: 0px; vertical-align: top;}
                .rule--value {
                    vertical-align: bottom;
                    display: flex;
                    flex-wrap: nowrap;
                    .rule--widget--SEARCH {
                        .time_frame_value {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }

                    .rule--widget--NUMBER {
                       .widget--widget  {
                           margin-left: 0px;
                           > .MuiFormControl-root {
                                width: 80px;
                            }
                       }
                    }

                    .widget--widget { margin-left: 0px; }
                    .rule--widget--EVENT, .rule--widget--SEARCH {
                        .widget--widget {
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .time_frame_value {
                            align-items: center;
                            span {
                                display: inline-block;
                                font-size: 14px;
                                vertical-align: middle;
                                vertical-align: -webkit-baseline-middle;
                                margin: 10px 0px 10px 0px;

                            }
                            .value--when {
                                .value--numeric {
                                    width: 60px;
                                }
                            }
                            .MuiFormControl-root {
                                width: 170px;
                            }
                            button {
                                margin-right: 0px;
                                padding: 0px;
                            }
                        }
                    }
                }
                }
                .rule--header {
                    button {
                        color: #5694EA;
                    }
                    svg {
                        width: 17px;
                        height: 17px;
                    }
                }
            }
            .rule_group {
                background-color: #ffffff;
                display: flex;
                flex-wrap: wrap;
                padding-left: 35px;
                .group--field {
                    align-self: auto;
                    margin-top: 5px;
                    width: 100%;
                }

                .group--actions {
                    display: none;
                }
                .qb-drag-handler {
                    position: absolute;
                    margin: 10px;
                    top: 10;
                    left: 0;
                    svg {
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }
    }
}
.subFilter--add_btn {
    height: 30px;
    margin: auto;
    > span {
        padding: 10px;
        font-size: 12px;
        color: #6b6b6b;
    }
    .active {
        > span {
            color: rgb(255, 169, 169);
        }
    }
}
.subfilter {
    margin: 5px;
    &.active {
        border: 1px dashed lightgrey;
        box-sizing: border-box;
        border-radius: 10px;
    }
    .subfilter_custom--content {
        display: flex;
        flex-wrap: wrap;
        .MuiFormControl-root {
            width: 100px !important;
        }
        :nth-child(2) {
            width: 130px !important;
        }
        :nth-child(4) {
            width: 80px;
        }
        span {
            display: inline-block;
            margin: auto 5px;
            font-size: 14px;
        }

        .MuiInputBase-root {
            font-size: 14px;
            padding-left: 5px;
            margin-right: 10px;
            height: 30px;
            border: 1px solid #D5DDED;
            border-radius: 3px;
            &::before {
                border-bottom: none;
                }
            }

        .subfilter--close_btn {
            vertical-align: middle;
            align-self: center;
            cursor: pointer;
            color: #6b6b6b;
            margin: 5px;
        }
    }
}

.popover__popup {
    position: absolute;
    bottom: calc(50% - 17px);
    color: #5694EA;
    right: 55px;
    &.active {
        color: #6b6b6b;
    }
}

.MuiAutocomplete-popper {
    z-index: 1500 !important;
}
