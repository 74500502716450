@import 'assets/scss/abstracts/_variables.scss';

.table_wrapper {
  border-radius: 0px;
  min-height: 400px;
  box-shadow: none !important;
  &.MuiTableContainer-root {box-shadow: $shadow-light !important; border-radius: 8px; }
  .table_container {
    position: relative;
    height: 500px;
    width: 100%;
    overflow: auto;
    .table {
      color: #2B437A;
      border-spacing: 0;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      position: absolute;
      top: 0px;
      width: 100%;
      .tr {
        color: inherit;
        display: table-row;
        outline: 0;
        vertical-align: middle;
        :last-child {
          .td {
            border-bottom: 0;
            border-right: 0px;
          }
        }
      }
      .td {
        min-height: 62px;
        margin: 0;
        border-bottom: 1px solid #EDF1F9;
        display: flex;
        padding: 15px;
        vertical-align: middle;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        line-height: 1.43;
        font-size: 13px;
        letter-spacing: 0.01071em;
        background-color: rgb(255, 255, 255);
        &:last-child {
          border-right: 0px;
          padding: 0px !important;
          max-width: 30px !important;
        }
        &:first-child {
          justify-content: flex-start;
          padding-left: 20px;
          padding-left: 20px;
          a {
            text-decoration: none;
            color: #2B437A;
            &:visited {
                color: #2B437A;
            }
        }
        }
      }
    }
    .th {
      align-items: center;
      min-height: 50px;
      margin: 0;
      border-bottom: 1px solid #EDF1F9;
      display: table-cell;
      height: 50px;
      padding: 18px;
      text-align: center;
      font-weight: 400;
      line-height: 1.43;
      font-size: 12px;
      letter-spacing: 0.01071em;
      vertical-align: inherit;
      background-color: rgb(255, 255, 255);
      border-top: none;
      img {
        padding-left: 7px;
      }
      &:first-child {
        text-align: left;
      }
      &:last-child {
        border-right: 0px;
        padding: 0px !important;
        max-width: 30px !important;
      }
    }
    &.sticky {
      .header {
        > .tr {
          position: sticky;
          z-index: 3;
        }
      }
    }
    .header {
      top: 0;
      position: sticky !important;
      z-index: 3;
      .th {
        border: 0 !important;
        font-weight: 500;
        color: rgb(99, 115, 129);
        background: rgb(244, 246, 248);
      }
      :first-child,
      :last-child {
        position: sticky !important;
      }
    }
    .body {
      position: relative;
      z-index: 0;
    }
    [data-sticky-td] {
      position: sticky;
    }
    .tr [data-sticky-first-right-td] {
      // border-left: 1px solid #D5DDED;
    }
  }
}

.MuiTableContainer-root {
  box-shadow: $shadow-light !important;
  border-radius: 12px;
  .thh {
    border: 0 !important;
    font-weight: 500 !important;
    color: rgb(99, 115, 129) !important;
    background: rgb(244, 246, 248) !important;
  }
}
