.table_container {
    position: relative;
    width: 100%;
    table {
        color: #061942;
        border-spacing: 0;
        position: absolute;
        top: 0px;
        width: 100%;
        overflow: scroll;
        tr {
        color: inherit;
        display: table-row;
        outline: 0;
        vertical-align: middle;
        :last-child {
            .td {
            border-bottom: 0;
            border-right: 0px;
            }
        }

        }
        th {
        background-color: #F9FAFD !important;
        color: #8291AE;
        font-size: 10px !important;
        border-top: 1px solid #D5DDED;
        }

        th, td {
        margin: 0;
        border-bottom: 1px solid #D5DDED;
        display: table-cell;
        height: 62px;
        padding: 18px;
        font-size: 0.875rem;
        text-align: center;
        font-weight: 400;
        line-height: 1.43;
        font-size: 12px;
        letter-spacing: 0.01071em;
        vertical-align: inherit;
        background-color: #FCFCFF;
        &:last-child {
            // border-left: 1px solid #ffffff;
            // background-color: rgb(255, 255, 255) !important;
        }
        &:first-child {
                text-align: left;
            }
        }

        thead {
            th {
                border-top: none;
            }
        }
    }
}

.table_container--checkbox {
    height: 400px !important;
    table {
        th {
            background-color: #ffffff !important;

        }
        th, td {
            background-color: #ffffff !important;
            text-align: left !important;

            &:first-child {
                max-width: 50px !important;
                width: 25px !important;
            }
        }
    }
}
