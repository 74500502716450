.data_buttons {
    display: flex;
    div {
      margin: 17px 0px 22px 22px;
      cursor: pointer;
      font-size: 14px;
      img {
        padding-right: 7px;
        vertical-align: text-bottom;
      }
    }
    .not-active {
      color: #8291AE;
    }

    .active {
      // color: red;
    }
  }