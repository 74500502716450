.locator_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-self: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 25px;

  & .action_step {
    text-align: right;
    display: inline-flex;
    width: 75%;
    margin-right: -75px;
    margin-left: auto;
    margin-bottom: 0;

    & .MuiStepper-root {
      background-color: transparent !important;
    }
  }
}
