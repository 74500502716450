@import 'assets/scss/abstracts/variables';

.alert {
  &.alert-neutral {
    background: $gray;
    color: #FFF;
    border-radius: calc($border-radius/2);

    &.outlined {
      background: transparent;
      border: 1px solid $light;
      color: $darkgray;

    }
  }

  &.alert-default {
    border-radius: calc($border-radius/2);
    background-color: $primary-light !important;
    border: 1px solid $primary;
    color: $darkgray;

    & .info-label {
      color: $primary;
    }

    &.borderless {
      border: 0px;
    }

    & .MuiAlert-message {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }
}
