.shopify {
        h3 {
        font-weight: 400;
    }
    .shopify--button {
        text-transform: none;
        background-color: #A93CCD;
        margin-top: 5px;
        font-weight: 400;
    }
}