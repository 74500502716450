.empty_state {
    box-sizing: border-box;
    border: 1px solid #EDF1F9;
    border-radius: 10px;
    margin-top: 30px;
    padding: 50px;
    text-align: center;
    background-color: #ffffff;
    h6 {
        font-weight: 400;
        font-size: 1rem;
        color: #061942;
        margin: 10px 0px;
    }
    p {
        font-weight: 400;
        color: #b4b4b4;
        margin: 0px;
    }
}