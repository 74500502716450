.search_component {
        flex-grow: 1;
        display: flex;
    .search_component--search {
        margin: 10px 0px 10px 10px;
        width: 100%;
        display: flex;
        flex-grow: 1;
        svg {
            color: #8291AE;
            height: 20px;
        }
        input {
            font-size: 14px;
            padding: 5px 10px;
        }
        
        width: 34px;
        fieldset {
            border-color: #D5DDED;
           
        }
        &.MuiOutlinedInput-root {
            & :hover {
            border-color: #D5DDED;
            & .MuiOutlinedInput-notchedOutline {
                border-color: #D5DDED;
            }
        }
    }
}
}