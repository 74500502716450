.right-box {
    max-width: 300px;
    .right-box__container {
        margin-bottom: 25px;
        .right-box__item {
            font-size: 12px;
            padding: 18px;
            height: 45px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #D5DDED;
            &:last-child {border-bottom: none;}
            .item--name {
                font-size: 10px;
            }
            .item--value {
                font-size: 14px;
            }
        }
    }
}
