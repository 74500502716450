.column_select {
    button {
        background-color: #ffffff;
        color: #8291AE;
        margin: 0 10px 0 0;
        display: flex;
        box-shadow: none;
        border: 1px solid #D5DDED;
        text-transform: none;
        font-weight: 400;
        &.MuiButton-contained {
            box-shadow: none;
        }
        img {
            margin-right: 7px;
        }
    }
}

    .column_select--content {
        height: 300px;
        display: flex !important;
        flex-direction: column !important;
        flex-wrap: wrap !important;
        width: 100%;
    }
