@import 'assets/scss/abstracts/variables';
@import 'assets/scss/abstracts/mixins';

.dndflow {
  background: $body-color;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 25;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: 0;
  transition: $transition;

  & .react-flow__node-input {
    padding: 12px 48px;
  }

  & .react-flow__edge-textbg {
    fill: #F6F8FC !important;
  }

  & .flow-node__floating-action {
    max-height: 60px !important;
    display: flex;
    background: #F5F8FC; //$body-color !important;
    background-color: #F5F8FC;// $body-color !important;
    box-shadow: $shadow-sm;
    position: absolute;
    z-index: 15;
    color: $gray;
    font-size: auto !important;
  }

  & .MuiBox-root:not(.add-step, .reportNode, .reportNode div) {
    margin-top: 65px;
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #F6F8FC;
  }

  & .reportNode {
    background: #F6F8FC;
    padding: 5px 10px ;
    width: 100% !important;
    margin-top: 5px;
    border-radius: 5px;

    & .results {
      // width: 60% !important;
      border-right: 1px solid #D5DDED;
    }

    & .user-stats {
      width: 40% !important;
      padding-left: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 100% !important;
      margin: auto;
    }
  }

  & .description {
    margin-bottom: 10px;
  }

  & .reactflow-wrapper {
    flex-grow: 1;
    height: auto;

    & .react-flow__renderer {
      transition: all .4s cubic-bezier(.4, 0, .2, 1) 100ms;
    }

    &.active .react-flow__renderer {
      transform: translateX(-150px);
    }
  }
}


//Add Step Block
@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;

    & .add-step {
      //@include on-circle($item-count: 3, $circle-size: 11rem, $item-size: 4rem);
      display: flex;
      flex-direction: column;
      box-shadow: $shadow-sm;
      background: #FFF;
      border-radius: 50px;
      padding: 15px;
      padding-inline: 20px;
      z-index: 35;

      & .add-step-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-inline: 5px;

        &:hover {
          & svg {
            filter: $text-shadow;
          }

          & .MuiIconButton-root {
            background: transparent;
            color: $default-light;
          }
        }

        &:focus,
        &:active {
          & svg {
            filter: $text-shadow;
          }

          & .MuiIconButton-root {
            background: transparent;
            color: $default;
          }
        }
      }

      & .MuiIconButton-root {
        display: inline-flex;
        flex-direction: column;
        border: 1px solid transparent;

        & button {
          filter: $text-shadow;
        }

        &:nth-of-type(3) {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}

// Add Step Edge
.react-flow__smoothstep-edge {
  margin: 100px;
  fill: none;
  stroke: #D9D9E5;
  border-radius: 50%;
  border-style: dashed;
  stroke-width: 1;

  &.disabled {
    opacity: .5;
  }
}

.branch-chip {
  padding: 0;
  font-size: 9px !important;
  color: $lightgray !important;
  background-color: $gray !important;
  padding-inline: 2px !important;
  margin-top: 10px;

  &.match {
    color: #FFF !important;
    background-color: #1976D2 !important;
  }
  &.no-match {
    color: #FFF !important;
    background-color: #93cfef !important;
  }
}

.edgebutton {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  align-items: flex-start;
  position: absolute;
  z-index: 100;
  transform: translate3d(calc(32px - 225%), calc(32px - 140%), 0px);
  background: #E6F5FD;
  border: 0px;
  border-radius: 50%;
  padding: 0;
  margin: 14px;
  cursor: pointer;
  height: 14px;
  width: 14px;
  line-height: 1;
  transition: box-shadow .2s ease-in, -webkit-box-shadow .2s ease-in;
  box-shadow: 0 0 0 5px #E6F5FD, 0 0 0 7px #5794EA;

  &:before {
    pointer-events: all;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  & .IconContainer {
    font-size: 12px;
    pointer-events: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    height: 24px;
    width: 24px;
    color: #5694EA;
    background-color: #FFF;
    border-radius: 50%;
    -webkit-transition: color .2s ease-in, -webkit-transform .2s ease-in;
    transition: color .2s ease-in, -webkit-transform .2s ease-in;
    transition: transform .2s ease-in, color .2s ease-in;
    transition: transform .2s ease-in, color .2s ease-in, -webkit-transform .2s ease-in;

    &svg.MuiSvgIcon-root {
      pointer-events: none !important;
    }

    & .isDropped {
      -webkit-animation: spin 1.2s linear infinite;
      animation: spin 1.2s linear infinite;
    }
  }

  &:hover,
  &.hover,
  &.isDragOver {
    box-shadow: 0 0 0 5px #C0E7F9, 0 0 0 7px #E6F5FD;
    background: #C0E7F9;

    & .IconContainer {
      pointer-events: none;
      opacity: 1;
      color: #FFF;
      background: #3D70E0 !important;
    }
  }

  &.isAnimating {
    -webkit-animation: pulse 1.6s infinite;
    animation: pulse 1.6s infinite;
    -webkit-box-shadow: 0 0 0 0 rgba(213, 221, 237, .9);
    box-shadow: 0 0 0 0 rgba(213, 221, 237, .9)
  }

  &.isAnimating.isDragOver,
  &.isAnimating:focus,
  &.isAnimating:hover {
    -webkit-animation: none;
    animation: none
  }

  &.isDragOver {
    -webkit-box-shadow: 0 0 0 10px rgba(213, 221, 237, .75);
    box-shadow: 0 0 0 10px rgba(213, 221, 237, .75)
  }
}

.edgebutton-foreignobject {
  pointer-events: none;
  align-items: center;
  background: transparent;
  display: flex;
  position: absolute;
  height: 50px;
  justify-content: center;
  width: 55px;

  &.badge {
    width: max-content;
  }

  &.disabled {
    opacity: .55;
  }
}

.react-flow__controls-button {
  margin: 0;
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(213, 221, 237, .9);
    box-shadow: 0 0 0 0 rgba(213, 221, 237, .9)
  }

  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(213, 221, 237, 0);
    box-shadow: 0 0 0 20px rgba(213, 221, 237, 0)
  }

  to {
    -webkit-box-shadow: 0 0 0 0 rgba(213, 221, 237, 0);
    box-shadow: 0 0 0 0 rgba(213, 221, 237, 0)
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(213, 221, 237, .9);
    box-shadow: 0 0 0 0 rgba(213, 221, 237, .9)
  }

  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(213, 221, 237, 0);
    box-shadow: 0 0 0 20px rgba(213, 221, 237, 0)
  }

  to {
    -webkit-box-shadow: 0 0 0 0 rgba(213, 221, 237, 0);
    box-shadow: 0 0 0 0 rgba(213, 221, 237, 0)
  }
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
  }
}
