@import 'assets/scss/abstracts/_variables.scss';

.layout {
    height: 100%;
    background-color: $lighter;
    color: #061942;
    padding-top: 18px;

    svg {
        color: #061942;
    }

    // .MuiListItem-root:hover {
    //     color: $primary;
    //     svg {
    //         color: $primary;
    //     }
    // }

    .MuiListItem-root.Mui-selected {
        background-color: #FAECFF;
        //border-right: 2px solid $primary;
        color: $primary;
        border-radius: 4px;

        svg {
            color: $primary;
        }
    }
   > ul {
        padding-left: 7px;
        padding-right: 7px;
    }

    .elemental_logo {
        padding-bottom: 37px;
        padding-left: 25px;
        padding-top: 5px;
        &:hover {
            cursor: pointer;
        }
    }

    .layout--arrows {
        display: flex;
        float: right;
        margin-right: 8px;
        margin-top: -7px;

        .layout--arrows-list {
            position: fixed;
            cursor: pointer;
            img {
                width: 19px;
                height: 19px;
            }
        }
    }
}

.landing_logo {
    display: flex;
    justify-self: center;
}
