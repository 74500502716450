.flow_query_filter {
    .query-builder-container {
        padding: 5px 0px 0px 0px;
        .query-builder {
            font-size: 16px;
            height: 100%;
                .rule_group {
                    .rule_group--children {
                    .group-or-rule-container {
                        > .rule  {
                            border: none !important;
                        }
                    }
               }
                .group-or-rule {
                    padding: 0px;
                    margin-left: -14px;
                     & ::before {
                        border-color: #D5DDED;
                      }
                      & ::after {
                        border-color: #D5DDED;
                    }
                }
                .group {
                    background-color: #FFFFFF;
                    border-color: #E1ECFB;
                    .group--header {
                        position: relative;
                        .group--conjunctions {
                            > span {
                                position: absolute;
                                right: 60px;
                            }
                            .MuiButton-containedPrimary {
                                background-color: #5694EA;
                            }
                            button {
                                text-transform: none;
                                font-weight: 400;
                                &.MuiButton-contained{
                                    background-color: #EFF5FD;
                                    &.MuiButton-containedPrimary {
                                        background-color: #5694EA;
                                    }
                                }
                                .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
                                    border-right: 1px solid #5694EA;
                                    opacity: 20%;
                                }
                            }
                        }
                        .group--actions {
                            justify-content: flex-start;
                            :nth-child(3) {
                                position: absolute;
                                right: 19px;
                            }
                            button {
                                color: #5694EA;
                                text-transform: none;
                                font-weight: 400;
                                svg {
                                    width: 17px;
                                    height: 17px;
                                }
                            }
                        }
                    }
                    .group--field {
                        // width: 260px;
                        align-self: left;
                        .MuiInputBase-root {
                        font-size: 14px;
                        padding-left: 5px;
                        // width: 260px;
                        height: 30px;
                        border: 1px solid #D5DDED;
                        border-radius: 3px;
                        &::before {
                            border-bottom: none;
                            }
                        }
                    }
                }

                .rule {
                    border: 1px solid #D5DDED;
                    display: flex;

                .rule--body {
                    display: flex;
                    .MuiInputBase-root {
                        margin: 5px;
                        font-size: 14px;
                        padding-left: 5px;
                        height: 30px;
                        border: 1px solid #D5DDED;
                        border-radius: 3px;
                        &::before {
                            border-bottom: none;
                        }
                    }

                    .rule--field {
                        vertical-align: top;
                        .MuiAutocomplete-root { width: 170px !important; min-width: 170px !important; }
                        .MuiInputBase-root {
                            margin: 5px;
                            .MuiInput-underline
                            &::before {
                                border-bottom: none;
                            }
                            &::after {
                                border-bottom: 1px solid #8d91a5;
                            }
                        }
                    }

                    .rule--operator { vertical-align: top; margin: 0px 5px; }
                    .rule--value {
                        vertical-align: bottom;
                        display: flex;
                        flex-wrap: wrap;
                        .rule--widget--SEARCH {
                            .time_frame_value {
                                display: flex;
                                flex-wrap: wrap;
                                &.time_frame--inline {
                                flex-direction: row;

                                }
                            }
                        }

                        .rule--widget--NUMBER {
                           .widget--widget  {
                               margin-left: 0px;
                               > .MuiFormControl-root {
                                    width: 80px;
                                }
                           }
                        }

                        .widget--widget { margin-left: 0px; }
                        .rule--widget--EVENT, .rule--widget--SEARCH {
                            .widget--widget {
                                // display: flex;
                                // flex-wrap: wrap;
                                display: block;
                            }
                            .time_frame_value {
                                span {
                                    display: inline-block;
                                    font-size: 14px;
                                    vertical-align: middle;
                                    vertical-align: -webkit-baseline-middle;
                                    margin: 10px 5px 10px 0px;
                                }
                                .value--when {
                                    .value--numeric {
                                        width: 60px;
                                    }
                                }
                                .MuiFormControl-root {
                                    width: 150px;
                                }
                                button {
                                    margin-right: 0px;
                                    padding: 0px;
                                }
                            }
                        }
                    }
                    }
                    .rule--header {
                        button {
                            color: #5694EA;
                        }
                        svg {
                            width: 17px;
                            height: 17px;
                        }
                    }


                }
                .rule_group {
                    background-color: #ffff;
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 35px;
                    .group--field {
                        align-self: auto;
                        margin-top: 5px;
                        width: 100%;
                    }

                    .group--actions {
                        display:none;
                    }
                    .qb-drag-handler {
                        position: absolute;
                        margin: 10px;
                        top: 10;
                        left: 0;
                        svg {
                            width: 17px;
                            height: 17px;
                        }
                    }
                }
            }
        }
        &.subfilter {
            .query-builder {
                margin-left: 0px;
                margin-right: 0px;
                .group-container {
                    padding-right: 0px;
                    .group--actions {
                        :nth-child(2) {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }

    .branch--filter {
        height: 100%;
        max-height: 650px;
        .flow-sidebar_title {
            margin-right: 25px;
        }
    }
    .subfilter {
        margin: 5px;
        &.active {
            border: 1px dashed lightgrey;
            box-sizing: border-box;
            border-radius: 10px;
        }
        .subfilter_custom--content {
            display: flex;
            flex-wrap: wrap;
            .MuiFormControl-root {
                width: 100px !important;
            }
            :nth-child(2) {
                width: 130px !important;
            }
            :nth-child(4) {
                width: 80px;
            }
            span {
                display: inline-block;
                margin: auto 5px;
                font-size: 14px;
            }

            .MuiInputBase-root {
                font-size: 14px;
                padding-left: 5px;
                margin-right: 10px;
                height: 30px;
                border: 1px solid #D5DDED;
                border-radius: 3px;
                &::before {
                    border-bottom: none;
                    }
                }
                .subfilter--close_btn {
                    vertical-align: middle;
                    align-self: center;
                    cursor: pointer;
                    color: #6b6b6b;
                    margin: 5px;
                }
            }
    }

    .popover__popup {
        position: absolute;
        bottom: calc(50% - 17px);
        color: #5694EA;
        right: 42px;
        &.active {
            color: #6b6b6b;
        }
    }
}
.bypass--filter {
    > button {
        float: right;
        margin-right: 24px;
    }
   .filter--check{
        padding: 25px;
        > label {
            margin-bottom: 10px;
        }
            .MuiTypography-root {
                font-size: 14px;
            }
        }
}
