.breadcrumbs {
  margin: 0;
  position: relative;
  z-index: 30;
  text-transform: capitalize !important;
  border-bottom: 1px solid #E5E5ED;
  width: 100%;
  display: inline-flex;
  padding: 0;

  & ul {
    padding: 0;
    display: inline-block;

    & li {
      color: #8291AE;
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
      padding: 12px 22px;
      position:relative;

      & .breadcrumb {
        line-height: 1 !important;
        text-transform: capitalize !important;
        margin-right: 10px !important;
      }

      &:after,
      &:before{
        position:absolute;
        content:"";
        height:0;
        width:1px;
        top:50%;
        //left:-25px;
        margin-top: -24px;
        border: 24px solid #FFF;
        border-right: 0 !important;
        border-left-color: transparent !important;
      }
      &:before{
        right:-24px;
        border: 24px solid $primary-light !important;
      }

      &.active {
        background-color: $primary-light;
        color: $primary;
        margin-bottom: 0;

        &:before {
          border-color: #FFF;
        }
      }
    }
  }
}
