h6 {
    color: #2B437A;
}
.btns {
    display: flex;
    gap: 12px;

    button {
        color: #061942;
        font-weight: 400;
        background-color: #e7ebf3;
        padding: 7px 25px;
    }
}
