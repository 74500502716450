@import 'assets/scss/abstracts/variables';

.flow-sidebar {
  background: #FFF;
  padding: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-self: center;
  // z-index: 50;
  overflow: hidden;
  height: 100%;
  min-width: 550px !important;
  transition: all .3s cubic-bezier(.4, 0, .2, 1) 100ms;
  & .flow-sidebar__header {
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    background: $lightgray;
    padding: 16px 20px;
    max-height: 55px;
    border-bottom: 1px solid $light;
    align-items: center;
    justify-content: space-between;

    & .flow-sidebar_title {
      display: flex;
      align-self: center;
      flex-direction: row;
      flex-grow: 0;
      color: #2B437A;
      flex-shrink: 0;
      width: max-content;
      overflow: hidden;
      box-sizing: border-box;
      font-weight: 500;
      border-bottom: 2px dashed $light;
      cursor: pointer;

      & .MuiSvgIcon-root {
        align-self: center;
        margin-right: 15px;
        font-size: 20px;
      }
      &.no-edit {
       border: none;
       cursor: default;
      }
    }


    & .MuiDivider-root {
      background: #EDF1F9;
      margin-left: 5px;
      margin-right: 5px;
    }

    & .flow-sidebar__actions {
      font-size: 20px;
      color: $secondary;
    }
  }

  .flow-sidebar_subtitle {
    color: $gray;
    font-weight: 400;
    line-height: 18px;
    margin: 25px;
    font-size: 14px;
  }

  & .flow-sidebar__body {
    min-width: 14vw;
    overflow: auto;
    //  max-height: max-content;
    //  height: max-content;
    height: auto;
    // & .MuiTabs-root {
    //   min-height: 36px;
    //   height: 36px;
    //   color: #9FB1D5;
    //   & .MuiTab-root {
    //     padding: 8px 6px;
    //     font-size: 14px;
    //     min-width: 100px;
    //     min-height: 36px;
    //     height: 36px;
    //     border-bottom: 2px solid #D5DDED;
    //     text-transform: capitalize;
    //     &.Mui-selected {
    //       color: #2B437A;
    //     }
    //   }
    // }

    & .collapse-expanded {
      //background: $lightgray;
    }

    & .flow-sidebar__body-content {
      min-height: max-content;
      max-height: 100%;
      font-size: 14px;
      padding: 25px;

      &.description {
        padding: 0 20px 10px 20px;
      }

      & .flow-sidebar_title {
        display: flex;
        align-self: center;
        flex-direction: row;
        flex-grow: 0;
        color: #2B437A;
        flex-shrink: 0;
        width: max-content;
        overflow: hidden;
        box-sizing: border-box;
        font-weight: 500;
      }

      & .flow-sidebar_description {
        display: flex;
        align-self: center;
        flex-direction: row;
        flex-grow: 0;
        color: $gray;
        flex-shrink: 0;
        box-sizing: border-box;
        font-weight: 400;
        line-height: 18px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
      }

      & .flow-sidebar__body-hihlighter {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        //background: #F9FAFD;
        //border-top: 1px solid $light;
        //border-bottom: 1px solid $light;
        color: #8291AE;
        font-size: 14px;

        & .MuiSvgIcon-root {
          margin-right: 5px;
        }
        svg {
          width: 24px;
          height: 24px;
        }
      }

      & .flex-sidebar__body-no-content {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F9F6FC;
        border-radius: 4px;
        width: 100%;
        height: 200px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 1rem;
        color: $gray;
      }
      .flow-sidebar__body-content-bar {
        box-sizing: border-box;
        border: 1px solid #D5DDED;
        border-radius: 19px;
        height: 40px;
        display: flex;
        margin: 10px;
        align-items: center;
        padding: 10px;
        color: $gray;
        svg {
          margin-right: 10px;
        }
        &.add {
          cursor: pointer;
          margin-bottom: 50px;
        }
      }
    }
  }
}
.wait__step {
  .MuiInputBase-root {
    font-size: 14px;
    max-height: 40px;
    margin-right: 10px;
    margin-bottom: 5px;
    &:last-child {
       margin-right: 0px;
    }
    p {
      font-size: 12px;
    }
  }
  .MuiFormControl-root {
    &.MuiInputBase-root {
      font-size: 12px;

    }
  }
}


.sidebar-send-test {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 10px 15px;
}
